<template>
  <div class="wrap">
    <!-- 顶部黑块 -->
    <div class="wrapTop">
      <!-- 视频内容 -->
      <div class="videoBox">
        <div class="wrapTopsCen">
          <div class="room-title">
            <div class="elp">
              {{ detail ? detail.title : '' }}
            </div>
            <div class="detail-ter flex-c">
              <div class="ter-list">
                <div class="terbox">
                  <el-image class="ter-img" :src="courDetail ? courDetail.tImg : ''" fit="cover" />
                </div>
                <span class="ter">讲师：{{ courDetail ? courDetail.terName : '' }}</span>
              </div>
            </div>
          </div>
          <!-- 视频上方提示 -->
          <div v-if="tipsShow" class="wrapTopsCenTop">
            <!-- 左侧提示 -->
            <div class="wrapTopsCenTopL">
              <img src="@/assets/img/kecheng/tipsIcon.png">
              <span>课程视频版权所有，禁止任何形式得转载！并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。</span>
            </div>
            <!-- 右侧关闭 -->
            <div class="wrapTopsCenTopR" @click="tipsShow = false">
              <span>关闭</span>
              <img src="@/assets/img/kecheng/classIcon1123.png">
            </div>
          </div>
          <!-- 中间视频 -->
          <div class="wrapTopsCenCen">
            <!-- 左视频 -->
            <!-- type:视频的类型  src：视频的路径 Controls：确定播放器是否具有用户可以与之交互的控件-->
            <div class="wrapTopsCenCenL">
              <div v-if="isMp4" id="J_prismPlayers" class="prism-player">
                <div id="logo">
                  <img :src="copyright.videoLogo" style="object-fit: contain">
                </div>
              </div>
              <jxyplayer v-if="
                  !isMp4 &&
                    uservideoConfig != '' &&
                    accessKeyId &&
                    accessKeySecret
                " :url="uservideoConfig" :autoplay="true" :access-key-id="accessKeyId"
                :access-key-secret="accessKeySecret" :logo-url="copyright.videoLogo" :options="option"
                @player="crePlayer" />
            </div>
            <!-- 当只有一个时样式 -->
            <div v-if="dottingList.length == 1" class="videoRBI isBottom">
              <div class="videoRBIContent">
                <div id="videoRBIBox" class="videoRBIBox">
                  <div v-for="(item, index) in dottingList" :key="index" class="contentItem onceItem">
                    <div class="img_box" @click="jumpTime(item, index)">
                      <img class="itemImg" :src="item.coverUrl">
                      <!-- <div class="nowPlaying" v-show="item.id == dottingId">
                        正在播放
                      </div> -->
                      <div v-show="item.id == thisdottingId" class="red_borders" />
                      <div class="dottingBox">
                        <!--  -->
                        <div v-if="item.showTime" class="dottingTime">
                          {{ item.showTime.slice(3, 8) }}
                        </div>
                      </div>
                    </div>
                    <div class="name_box">
                      <div class="dotting_names">
                        {{
                          item.knowledgeList &&
                            item.knowledgeList[0] &&
                            item.knowledgeList[0].name
                            ? item.knowledgeList[0].name
                            : ''
                        }}
                      </div>
                      <div class="onceBtn">
                        <div class="item_btn">
                          <img class="item_img" src="@/assets/img/Home/Vector2.png" alt="">
                          <span class="btn_name" @click="openOrClose(item)">{{
                            item.isOpen ? '收起知识点' : '展开知识点'
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.isOpen" class="dsj" />
                  </div>
                  <div class="nomore">
                    <img src="@/assets/img/kecheng/nomore.png" alt="">
                    <span>暂无更多知识点~</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="dottingList.length > 1" class="videoRBI">
              <div class="arrow" @click="leftArrow">
                <i class="iconfont icon-xiangzuo" />
              </div>
              <div class="videoRBIContent">
                <div id="videoRBIBox" class="videoRBIBox">
                  <div v-for="(item, index) in dottingList" :key="index" class="contentItem">
                    <div class="img_box" @click="jumpTime(item, index)">
                      <img class="itemImg" :src="item.coverUrl">
                      <div v-show="item.id == thisdottingId" class="red_border" />
                      <div class="dottingBox">
                        <!--  -->
                        <div v-if="item.showTime" class="dottingTime">
                          {{ item.showTime.slice(3, 8) }}
                        </div>
                      </div>
                    </div>
                    <div class="name_box">
                      <div class="dotting_name">
                        {{
                          item.knowledgeList &&
                            item.knowledgeList[0] &&
                            item.knowledgeList[0].name
                            ? item.knowledgeList[0].name
                            : ''
                        }}
                      </div>
                      <div class="item_btn">
                        <img class="item_img" src="@/assets/img/Home/Vector2.png" alt="">
                        <span class="btn_name" @click="openOrClose(item)">{{
                          item.isOpen ? '收起知识点' : '展开知识点'
                        }}</span>
                      </div>
                    </div>
                    <div v-if="item.isOpen" class="dsj" />
                  </div>
                </div>
              </div>
              <div class="arrow" @click="rightArrow">
                <i class="iconfont icon-xiangyou" />
              </div>
            </div>
          </div>
        </div>
        <bofang-chater v-if="ifNew" :id="courseId" class="room" :playid="id" :class-id="classId"
          :class-product-line="classProductLine" :is-purchase="true" :list="courDetail ? courDetail.list : null"
          :dot="dottingList.length ? true : false" />
      </div>
    </div>
    <!-- 知识点 -->
    <transition name="fade">
      <div v-show="isOpen" class="knowledge_points-box">
        <kowledgePoints ref="kowledgePoints" type="2" />
      </div>
    </transition>
    <!-- 选项栏 -->
    <div class="content">
      <div class="tabTwo m-t-24">
        <div class="tabTwo-text" :class="NavIndex == 1 ? 'active' : ''" @click="NavIndex = 1">
          课程答疑
          <div v-if="NavIndex == 1" class="on" />
        </div>
        <div class="tabTwo-text" :class="NavIndex == 2 ? 'active' : ''" @click="NavIndex = 2">
          课程评分
          <div v-if="NavIndex == 2" class="on" />
        </div>
      </div>
      <!-- 课程答疑 -->
      <answer-view v-if="NavIndex == 1" :type="1" :is-purchase="true" :status="2" :course-id="courseId"
        :chapter-id="id" />
      <!-- 课程评分 -->
      <score-evaluation v-if="NavIndex == 2" :belonging="2" :chapter-id="groupId" :section-id="id" :type="3"
        :type-id="courseId" :type-nmae="detail.title" :is-purchase="true" />
    </div>

    <ratingPopup :show="ratingPopupShow" :type="2" :course-id="courseId" :chapter-id="detail.groupId"
      :teacher-id="teacherId" :section-id="id" :is-purchase="true" @close="closeRatingPopup" />
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : '' }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : '' }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getClassCourseByVideoId,
  getClassCourseById,
  getvideoSegmentList,
  selectSupplyToStudentStudyRate
} from '@/api/home'
import scoreEvaluation from '@/components/Know/course/scoreEvaluation.vue'
import ratingPopup from '@/components/ratingPopup.vue'
import jxyplayer from '@/components/jxyPlay.vue'
import answerView from '@/components/Know/course/answerView.vue'
import bofangChater from '@/components/Know/bofangChaternew.vue'
import {
  Know,
  sendVideoHistoryDataBatch,
  courseCheckPlay,
  judgeIsComment
} from '@/api/know'
const know = new Know()
import { getInfo, getDomain } from '@/api/cookies'
import { getAccessKey } from '@/api/home'
import kowledgePoints from '@/components/kowledgePoints.vue'
import { newWebSocket } from '@/common/utils/newWebSocket.js'
import Vue from 'vue'

export default {
  components: {
    bofangChater,
    scoreEvaluation,
    answerView,
    jxyplayer,
    ratingPopup,
    kowledgePoints
  },
  data () {
    return {
      copyright: {}, // 机构信息
      userInfo: {},
      // 课程详情信息
      courDetail: {},
      ifNew: false,
      // 课程节详情信息
      detail: {
        courseId: ''
      },
      // 时间戳用于学习记录
      timeStamp: new Date().getTime(),
      isAdded: false, // 保证记录成对出现
      uservideoConfig: '',
      NavIndex: 1,
      tipsShow: true,
      IsPurchase: false,
      option: {
        width: '880px',
        height: '498px',
        url: this.uservideoConfig,
        type: 'HLS',
        aDType: 'swiper'
      },
      id: null, // 章节ID
      chapterName: null, // 章节名称
      groupId: null, // 章节组ID
      courseId: null, // 录播ID
      classId: null,
      player: null,
      playerTime: 0,
      dottingList: [], // 打点视频列表
      dottingId: null, // 选中的打点ID
      thisdottingId: null, // 选中的打点ID
      finishTime: null, // 选中的打点视频时长 + 已播放时长
      progressMarkers: [], // 进度条打点列表
      marginLeft: 0,
      /* 学习记录 */
      setIntervals: null,
      timer: null,
      isMp4: false,
      classProductLine: 0,
      status: 0,
      getExplorer: '', // 当前浏览器
      history: 0,
      socket: null,
      accessKeySecret: null,
      accessKeyId: null,
      ratingPopupShow: false,
      teacherId: null,
      isOpen: false,
      judgeCommen: null,
      watchTime: 0,
      currentData: {},
      lookDeatil: null,
      showLookDetail: false,
      timePointBatch: [],//批量存储的学习率秒点
    }
  },
  beforeDestroy () {
    // this.getLbClassCourse();
    this.postLiveTime()
    this.stopLearn()
    clearInterval(this.setIntervals)
    if (this.isMp4) {
      this.player.dispose()
    } else {
      this.player.destroy()
    }
    this.player = null
  },
  async created () {
    this.IsPurchase = true
    this.ifNew = false
    this.userInfo = getInfo()
    this.copyright = getDomain()
    await this.getAccessKey()
    /* this.addSocket() */
    this.$forceUpdate()
    if (!this.userInfo) {
      Vue.prototype.goLoginView
      return
    }
    this.classProductLine = this.$route.query.classProductLine
      ? Number(this.$route.query.classProductLine)
      : 0
    this.id = this.$route.query.id
    this.chapterName = this.$route.query.chapterName
    this.courseId = await this.$route.query.courseId
    this.classId =
      (await this.$route.query.classId) && Number(this.$route.query.classId) > 0
        ? Number(this.$route.query.classId)
        : null
    this.groupId = this.$route.query.groupId
    await getClassCourseByVideoId(this.id).then(async (res) => {
      console.log(res.data.msg)
      this.detail = res.data.msg
      this.detail.audition = this.detail.audition
        ? Number(this.detail.audition)
        : 0
    })
    if (this.$route.query.uservideoConfig.endsWith('mp4')) {
      this.isMp4 = true
      this.uservideoConfig = this.$route.query.uservideoConfig
    } else {
      this.isMp4 = false
      this.uservideoConfig = `${this.$route.query.uservideoConfig}&bucketName=${this.$route.query.bucketName}&fileName=${this.$route.query.fileName}`
      console.log(
        this.uservideoConfig,
        '播放器地址------------------------------------------'
      )
    }
    await getClassCourseById(
      this.courseId,
      this.classId ? this.classId : undefined
    ).then(async (res) => {

      this.teacherId = res.data.details.teacherId
      this.courDetail = res.data.details

      this.$forceUpdate()
    })
    // 录播课程详情信息
    await this.findTrackingVideoRecord()
    await this.getvideoSegmentList()
    // this.addLearn()
    this.ifNew = true

    if (this.isMp4) {
      await this.getTimeHistory()
      await this.crePlayer()
    }
    this.judgeIsComment()
  },
  destroyed () {
    console.log('====================移除==========================')
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
    newWebSocket.close()
  },
  mounted () {
    console.log('----dianbofangnew---')
    // 添加监听事件
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e))
    // source  终端 1 PC 2 M  3 APP 4 IOS 5  聚学U APP  6  聚学U IOS   7 聚学堂小程序 program   8 聚学U小程序
    // showId 视频节id
    // type  1直播 2录播 3音频 4图文 5面授

    // 连接ws
    const sub = {
      courseType: '2', // 1直播课/2录播课
      userid: this.userInfo.id,
      tenantid: this.userInfo.tenantId || null,
      source: 1
    }
    // let data = Vue.prototype.export(sub)
    const url = `/${Vue.prototype.Base64.encode(JSON.stringify(sub))}`
    // 限制只能一个人只能看一个课
    courseCheckPlay({
      courseType: '2',
      userId: this.userInfo.id,
      chapterId: this.id,
      source: 1
    }).then((res) => {
      console.log(res, '-----------------可以观看吗dianbofangnew-------')
      if (res.code == 0) {
        newWebSocket.init({
          url: url,
          onopen: (msg, data) => {
            console.log(msg, data)
          },
          onmessage: (data) => {
            console.log(data)
          },
          onclose: (data) => {
            console.log(data)
          }
        })
      } else {
        this.lookDeatil = res.msg
        this.showLookDetail = true
      }
    })
  },
  methods: {
    closeLook () {
      this.showLookDetail = false
    },
    // 是否评论过
    judgeIsComment () {
      judgeIsComment({
        stuId: this.userInfo.id,
        type: '2',
        courseId: this.courseId,
        chapterId: this.id,
        sectionId: ''
      }).then((res) => {
        this.judgeCommen = res.data
      })
    },
    async getAccessKey () {
      const res = await getAccessKey()
      if (res.code == 0) {
        this.accessKey = res.data
        this.accessKeyId = res.data.accessKeyId
        this.accessKeySecret = res.data.accessKeySecret
      }
    },
    beforeunloadHandler (e) {
      this.postLiveTime()
    },
    onbeforeunload () {
    
    },
    getTime () {
      if (this.isMp4) {
        this.playerTime = Math.floor(this.player.getCurrentTime())
        console.log(this.playerTime)
      } else {
        this.playerTime = Math.floor(this.player.currentTime)
        console.log(this.playerTime)
      }
      
      if (this.isMp4) {
        const rateHtml = this.player.getComponent('RateComponent').html
        let rate = $(rateHtml).find('.current-rate').text()
        rate = parseFloat(rate)
        this.getLbClassCourse(this.playerTime, rate)
      } else {
        const rate = this.player.playbackRate
        this.getLbClassCourse(this.playerTime, rate)
      }
      this.timer = setTimeout(
        this.getTime,
        1000 /* Math.round((1000 - 100) / rate) */
      )
    },
    /* 查询课程章节是否已观看 */
    async findTrackingVideoRecord () {
      
    },
    /* 添加播放历史 */
    async postLiveTime () {
      await know.addStudentLearningPlaybackRecords(
        1, // 张永涛说回放属于直播类型
        this.userInfo.id,
        this.courseId,
        this.id,
        this.playerTime
        
      )
    },
    /* 获取播放历史 */
    async getTimeHistory () {
      await know
        .getStudentLearningPlaybackRecords(
          1,
          this.userInfo.id,
          this.courseId,
          this.id
        )
        .then((res) => {
          if (res.code == 0) {
            if (res.msg && res.msg.duration) {
              this.history = res.msg.duration
              console.log(this.history, '-----播放历史55555555-----')
              this.playerTime = res.msg.duration
            }
          }
        })
    },
    clear () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    // 课程概述
    nbvs (val) {
      this.NavIndex = val
    },

    // 播放
    async crePlayer (player) {
      
      if (
        (!this.IsPurchase || !this.userInfo) &&
        (!this.detail.audition || this.detail.audition === '0')
      ) {
        this.$message.error('未购买不能观看！')
        return
      }
      
      const that = this
      console.log(this.uservideoConfig.endsWith('mp4'), '---MP4-------')
      if (this.isMp4) {
        console.log('MP4')
        var ProgressMarkerComponent = Aliplayer.Component({
          createEl: function (el) {
            const htmlString = `<div class="boxTips">
                              <img class="marker-img" />
                              <div class="marker-text"></div>
                            </div>`
            this.container = $(htmlString)
            $(el).append(this.container)
          },
          markerDotOver: function (player, data) {
            const progressMarker = data.progressMarker
            this.container
              .find('.marker-img')
              .attr('src', progressMarker.imgUrl)
            this.container.find('.marker-text').text(progressMarker.text)
            this.container.css('left', data.left * 100 + '%')
            this.container.css('display', 'block')
          },
          markerDotOut: function (player, data) {
            that.$nextTick(() => {
              this.container.css('display', 'none')
            })
          }
        })
        await setTimeout(async () => {
          that.player = new Aliplayer(
            {
              id: 'J_prismPlayers',
              source: that.uservideoConfig,
              qualitySort: 'asc',
              format: 'mp4',
              mediaType: 'video',
              width: '100%',
              height: '100%',
              autoplay: true,
              isLive: /*   that.classProductLine == 3 && that.status == 0 ? true : */ false,
              rePlay: false,
              playsinline: true,
              preload: true,
              track: false,
              Caption: false,
              controlBarVisibility: 'clikc',
              showBarTime: 5000,
              useH5Prism: true,
              components: [
                {
                  name: 'progressMarkerComponent',
                  type: ProgressMarkerComponent
                },
                {
                  name: 'RateComponent',
                  type: AliPlayerComponent.RateComponent
                }
              ],
              progressMarkers: this.progressMarkers
            },
            (player) => {
              if (!that.IsPurchase) {
                if (that.detail.audition) {
                  if (that.detail.audition == -1) {
                    player.setPreviewTime(0)
                  } else {
                    const tiem = that.detail.audition * 60
                    player.setPreviewTime(tiem)
                    player.on('pause', function () {
                      const getCurrentTime = player.getCurrentTime()
                      if (getCurrentTime == tiem || getCurrentTime > tiem) {
                        that.$message('试看已结束，购买后观看完整视频')
                      }
                    })
                  }
                }
              } else {
                player.setPreviewTime(0)
              }
              // that.getLbClassCourse(); /* 添加学习记录 */

              player.on('timeupdate', function () {
                // 这个方法250毫秒触发一次

                if (that.player.getCurrentTime() > that.finishTime) {
                  if (that.dottingId) {
                    that.player.pause()
                  }
                  that.dottingId = null
                }
              })
              player.on('playing', function () {
                console.log('---开始播放---')
                that.addLearn()
                if (!that.timer) {
                  that.getTime()
                }
              })
              player.on('ended', function () {
                player.fullscreenService.cancelFullScreen()
                console.log('播放已结束!')
                that.clear()
              })
              player.on('pause', function () {
                console.log('---暂停---')
                that.stopLearn()
                that.clear()
              })
              player.on('error', function () {
                that.clear()
              })
              player.on('ready', function () {
                console.log('-----ready---12')
                const rateList = document.querySelector('.rate-list')
                rateList.removeChild(rateList.children[0])
                rateList.removeChild(rateList.children[1])
                rateList.removeChild(rateList.children[2])
                
                that.player.seek(that.history)
              })
            }
          )
        }, 100)
      } else {
        that.player = player
        if (!that.IsPurchase) {
          if (that.detail.audition) {
            console.log(that.detail.audition, '5555555555555555555555')
            if (that.detail.audition == -1) {
            } else {
              const tiem = that.detail.audition * 60
              // player.setPreviewTime(tiem);
              player.on('timeupdate', function () {
                const getCurrentTime = that.player.currentTime
                if (getCurrentTime == tiem || getCurrentTime > tiem) {
                  player.currentTime = 0
                  player.pause()
                  that.$message('试看已结束，购买后观看完整视频')
                }
              })
            }
          }
        } else {
          
        }
        

        player.on('timeupdate', function () {
          // 这个方法250毫秒触发一次

          if (that.player.currentTime > that.finishTime) {
            if (that.dottingId) {
              that.player.pause()
            }
            that.dottingId = null
          }
        })
        player.on('playing', function () {
          that.addLearn()
          if (!that.timer) {
            that.getTime()
          }
        })
        player.on('ended', function () {
          player.fullscreenService.cancelFullScreen()
          that.clear()
        })
        player.on('pause', function () {
          that.stopLearn()
          that.clear()
        })
        player.on('error', function () {
          that.clear()
        })
        player.on('ready', async function () {
          await that.getTimeHistory()
          setTimeout(() => {
            player.play()
          }, 1000)
          that.player.currentTime = that.history
        })
      }
      setTimeout(() => {
        selectSupplyToStudentStudyRate({
          userId: this.userInfo.id,
          showId: that.detail.id,
          courseType: 2
        })
      }, 2000)
      that.onbeforeunload()
      window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
    },
    /* 打点视频列表 */
    async getvideoSegmentList () {
      const data = {
        parentId: this.id,
        videoType: 'videoCourses'
      }
      await getvideoSegmentList(data).then((res) => {
        res.data.forEach((item) => {
          this.$set(item, 'isOpen', false)
        })
        this.dottingList = res.data
        res.data.forEach((item) => {
          const time = item.startTime.split(':')
          const hour = time[0]
          const minute = time[1]
          const second = time[2]
          const playTime =
            Number(hour * 60 * 60) + Number(minute * 60) + Number(second)
          item.startTimeNum = playTime
          const obj = {
            offset: playTime,
            isCustomized: true,
            text: item.knowledgeList[0]?.name,
            imgUrl: item.coverUrl
          }
          this.progressMarkers.push(obj)

          const lengthTime = Number(item.lengthTime)
          const h = Math.floor(lengthTime / 60 / 60)
          const m = Math.floor((lengthTime - h * 60 * 60) / 60)
          const s = lengthTime - h * 60 * 60 - m * 60
          item.showTime = `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s
            }`
        })
      })
    },
    /* 点击打点视频跳转播放进度 */
    jumpTime (item, index) {
      if (this.player && this.isMp4) {
        this.player.seek(this.progressMarkers[index].offset)
      }
      this.dottingId = item.id
      this.thisdottingId = item.id

      const lengthTime = Number(item.lengthTime)
      if (this.isMp4) {
        this.finishTime = Math.floor(this.player.getCurrentTime() + lengthTime)
      } else {
        this.player.currentTime = Math.floor(item.startTimeNum)
        setTimeout(() => {
          this.player.play()
        }, 1000)
      }
    },
    /* 视频打点部分点击左箭头 */
    leftArrow () {
      if (this.marginLeft > -1) {
        return
      } else {
        const videoRBIBox = document.getElementById('videoRBIBox')
        videoRBIBox.style.left = this.marginLeft + 168 + 'px'
        this.marginLeft += 168
      }
    },
    /* 视频打点部分点击右箭头 */
    rightArrow () {
      const videoRBIBox = document.getElementById('videoRBIBox')
      const videoRBIBoxWidth = videoRBIBox.clientWidth
      if (videoRBIBoxWidth - 1000 > Math.abs(this.marginLeft)) {
        this.marginLeft -= 168
        videoRBIBox.style.left = this.marginLeft - 168 + 'px'
      }
    },
    /* --------学习记录--------------- */ /* 添加学习率 */
    async getLbClassCourse (currTime, rate) {//自测通过
      console.log("学习率dianbofangnew")
      this.watchTime += 1//看了就+1

      this.timePointBatch.push(currTime)
      if( (currTime%30) == 0 ){//每30s发送一次
        this.sendTimePointBatch(rate)
      }

    },



    async sendTimePointBatch(rate){
      // 封装要发送的数据
      const postData = {
          userId: this.userInfo && this.userInfo.id,
          classId: this.classId ? this.classId : this.coursePackageId,
          coursePackageId: this.coursePackageId
            ? this.coursePackageId
            : undefined,
          courseId: this.courseId,
          showId: this.id,
          tenantId: this.userInfo && this.userInfo.tenantId,
          timePointBatch: this.timePointBatch,
          multiple: rate,
          // duration: this.player.getDuration(),
          duration: this.isMp4
            ? Math.floor(this.player.getDuration())
            : this.player.duration,
          courseType: 2,
          agentType: 0,
          isLive: 0
        }


        //调用后端接口
        sendVideoHistoryDataBatch(postData).then((res) => {
          if (res.code == 500) {
            clearTimeout(this.timer)
            this.timer = null
          } else {
            
            //批量上报成功，清空数组
            this.timePointBatch = []//清空

            if (this.isMp4) {
              if (
                Math.floor(this.player.getDuration()) >= 1200 &&
                this.watchTime >= Math.floor(this.player.getDuration() * 0.9) &&  //强行 ==90%。为什么不能写为 >90%
                this.ratingPopupShow == 'false' &&
                this.judgeCommen == 'false'
              ) {
                this.ratingPopupShow = true
              }
            } else {
              if (
                Math.floor(this.player.duration) >= 1200 &&
                this.watchTime >= Math.floor(this.player.duration) * 0.9 &&
                this.ratingPopupShow == 'false' &&
                this.judgeCommen == 'false'
              ) {
                this.ratingPopupShow = true
              }
            }

          }
        })

    },







    /* 添加学习记录 */
    async addLearn () {
      if (this.isAdded) {
        return
      }
      this.isAdded = true
      this.timeStamp = new Date().getTime()
      const data = {
        courseType: '2',
        userid: this.userInfo.id,
        tenantid: this.userInfo.tenantId || null,
        classId: this.classId ? this.classId : this.coursePackageId,
        coursePackageId: this.coursePackageId
          ? this.coursePackageId
          : undefined,
        chapterId: this.id,
        groupId: this.groupId || null,
        kid: this.courseId,
        timeStamp: this.timeStamp,
        chapterName: this.chapterName,
        source: 1
      }
      newWebSocket.sendMessage(JSON.stringify(data))

    },
    // 视频暂停发送ws学习记录暂停调用
    stopLearn () {
      const data = {
        courseType: '2',
        userid: this.userInfo.id,
        tenantid: this.userInfo.tenantId || null,
        classId: this.classId ? this.classId : this.coursePackageId,
        coursePackageId: this.coursePackageId
          ? this.coursePackageId
          : undefined,
        chapterId: this.id,
        groupId: this.groupId || null,
        kid: this.courseId,
        timeStamp: this.timeStamp,
        chapterName: this.chapterName,
        source: 1
      }
      newWebSocket.sendMessage(JSON.stringify(data))
      this.isAdded = false
    },

    // 展开知识点
    openOrClose (item) {
      if (item.id == this.currentData.id) {
        item.isOpen = !item.isOpen
        this.isOpen = !this.isOpen
      } else {
        item.isOpen = true
        this.isOpen = true
        this.currentData.isOpen = false
      }
      this.currentData = item
      this.$refs.kowledgePoints.getData(item.id)
    },

    closeRatingPopup () {
      this.ratingPopupShow = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .xgplayer-pc {
  position: relative;
  img {
    position: absolute;
    top: 85% !important;
    right: 40px !important;
    width: 105px !important;
  }
}
.wrap {
  width: 100%;
  height: 100%;
  // 顶部黑块
  .wrapTop {
    width: 100%;
    overflow: hidden;
    background: #222222;
    border-radius: 0px 0px 0px 0px;
    // 上方课程名
    .wrapTopsTop {
      width: 100%;
      height: 58px;
      line-height: 58px;
      background: #383838;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
      padding: 18px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // 左侧标题
      .wrapTopsTopL {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        // 图片

        // 文字标题
        p {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #aaaaaa;
          span {
            color: #ffffff;
          }
        }
      }
      // 右侧返回课程介绍
      .wrapTopsTopR {
        width: auto;
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        // 文字
        span {
          width: auto;
          height: auto;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
        // 返回图片
        img {
          width: 13px;
          height: 11px;
          margin-right: 5px;
        }
      }
    }
    .videoBox {
      min-height: 556px;
      max-height: 720px;
      margin: 32px auto;
      display: flex;
      justify-content: center;
      // 视频内容
      .wrapTopsCen {
        width: 880px;
        margin-right: 20px;
        position: relative;
        // 视频上方提示
        .wrapTopsCenTop {
          width: 100%;
          height: 40px;
          background: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          top: 58px;
          z-index: 99;
          // 左侧提示
          .wrapTopsCenTopL {
            width: auto;
            height: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin: 0px 10px 0px 10px;
            }
            span {
              width: auto;
              height: auto;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #f15817;
            }
          }
          // 右侧关闭
          .wrapTopsCenTopR {
            width: auto;
            height: 12px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            span {
              width: auto;
              height: auto;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #a8abbe;
            }
            img {
              width: 11px;
              height: 11px;
              margin: 0px 10px 0px 5px;
            }
          }
        }
        // 中间视频
        .wrapTopsCenCen {
          width: 100%;
          margin-top: 1px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // 左视频
          .wrapTopsCenCenL {
            width: 100%;
            height: 495px;
            margin-bottom: 20px;
            .prism-player {
              width: 100%;
              height: 100%;
              position: relative;
              border-radius: 8px;
            }
            ::v-deep {
              video {
                width: 100%;
                height: 100%;
                border-radius: 8px;
              }
            }
          }
          .isBottom {
            width: 100%;
            height: 156px !important;
            padding-top: 15px;
            padding-left: 19px;
            background-color: #fff;
            background: #1f1e24;
            border-radius: 8px;
            .videoRBIContent {
              margin: 0 !important;
            }
          }
          /* 左视频打点 */
          .videoRBI {
            height: 146px;
            width: 100%;
            display: flex;
            .arrow {
              width: 16px;
              height: 146px;
              background-color: rgba(0, 0, 0, 0.5);
              border-radius: 4px;
              text-align: center;
              line-height: 146px;
              color: #ffffff;
              cursor: pointer;
            }
            .videoRBIContent {
              width: 832px;
              height: 169px;
              margin: 0 8px;
              overflow: hidden;
              position: relative;
              .videoRBIBox {
                display: flex;
                position: absolute;
                left: 0;
                top: 0;
                .nomore {
                  display: flex;
                  align-items: center;
                  width: 360px;
                  height: 122px;
                  background: #333333;
                  border-radius: 8px;
                  margin-left: 8px;
                  padding-top: 13px;
                  padding-left: 37px;
                  font-size: 14px;
                  font-family: MicrosoftYaHei;
                  color: #c9c9c9;
                  line-height: 27px;
                  img {
                    width: 110px;
                    height: 110px;
                    margin-right: 16px;
                  }
                }
                .onceItem {
                  display: flex;
                  width: 456px !important;
                  height: 122px !important;
                  background: #ffffff;
                  border-radius: 8px;
                  padding-top: 4px;
                  padding-left: 6px;
                  .img_box {
                    position: relative;
                    width: 197px !important;
                    height: 111px !important;
                    margin-right: 15px;
                    img {
                      width: 100% !important;
                      height: 100% !important;
                    }
                    .dottingBox {
                      position: absolute;
                      bottom: 0;
                      background: transparent !important;
                    }
                  }
                  .name_box {
                    width: 231px !important;
                    padding: 0 !important;
                    .dotting_names {
                      width: 231px !important;
                      height: 54px;
                      font-size: 16px !important;
                      font-family: MicrosoftYaHei;
                      color: #333333;
                      line-height: 27px;
                      text-overflow: -o-ellipsis-lastline;
                      overflow: hidden; //溢出内容隐藏
                      text-overflow: ellipsis; //文本溢出部分用省略号表示
                      display: -webkit-box; //特别显示模式
                      -webkit-line-clamp: 2; //行数
                      line-clamp: 2;
                      -webkit-box-orient: vertical;
                      margin-bottom: 18px;
                    }
                    .onceBtn {
                      width: 100%;
                    }
                    .item_btn {
                      justify-content: center;
                    }
                  }
                }
                .contentItem {
                  width: 160px;
                  height: 146px;
                  margin-right: 8px;
                  position: relative;
                  .img_box {
                    width: 160px;
                    height: 90px;
                    position: relative;

                    .itemImg {
                      width: 160px;
                      height: 90px;
                      border-radius: 8px;
                      cursor: pointer;
                    }
                    .nowPlaying {
                      width: 64px;
                      height: 24px;
                      border-radius: 8px 0 8px 0;
                      background-color: #ff5e51;
                      text-align: center;
                      line-height: 24px;
                      font-size: 12px;
                      color: #ffffff;
                      position: absolute;
                      top: 0;
                      left: 0;
                      cursor: pointer;
                    }
                    .red_borders {
                      border: 2px solid #ff5e51;
                      border-radius: 8px 8px 8px 8px;
                      width: 197px;
                      height: 111px;
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                    .red_border {
                      border: 2px solid #ff5e51;
                      border-radius: 8px 8px 0 0;
                      width: 160px;
                      height: 90px;
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                    .dottingBox {
                      width: 160px;
                      height: 24px;
                      border-radius: 0 0 8px 8px;
                      background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 0.6) 100%
                      );
                      color: #ffffff;
                      line-height: 24px;
                      font-size: 12px;
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      display: flex;
                      justify-content: space-between;
                      padding: 0 8px;
                      cursor: pointer;
                    }
                  }

                  .name_box {
                    background-color: #ffffff;
                    width: 160px;
                    height: 56px;
                    border-radius: 0 0 8px 8px;
                    padding: 10px 0 0 12px;

                    .dotting_name {
                      color: #373a42;
                      font-size: 12px;
                      width: 132px;
                      margin-bottom: 3px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                    .item_btn {
                      display: flex;
                      align-items: center;
                      cursor: pointer;

                      .item_img {
                        margin-right: 4px;
                        width: 10px;
                        height: 10px;
                      }
                      .btn_name {
                        color: #ff5e51;
                        font-size: 12px;
                        font-weight: 500;
                      }
                    }
                  }
                }
              }
            }
          }
          // 右头像/聊天
          .wrapTopsCenCenR {
            width: 25%;
            height: 100%;
            // 讲师头像
            .wrapTopsCenCenRT {
              width: 100%;
              height: 190px;
              background: #ffffff;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              img {
                width: 135px;
                height: 113px;
                margin-top: 26px;
              }
              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #6a6a6a;
              }
            }
            // 聊天框
            .wrapTopsCenCenRC {
              width: 100%;
              height: 346px;
              // 聊天框顶部
              .wrapTopsCenCenRCa {
                width: 100%;
                height: 40px;
                background: #464646;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #efefef;
              }
              // 聊天框内容
              .wrapTopsCenCenRCb {
                width: 100%;
                height: 260px;
                overflow: auto;
                // 内容盒子
                .wrapTopsCenCenRCbs {
                  width: 85%;
                  height: 32px;
                  margin: 20px auto;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  // 左侧头像
                  .wrapTopsCenCenRCbsL {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }
                  // 右侧昵称/留言
                  .wrapTopsCenCenRCbsR {
                    width: auto;
                    height: 32px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    span {
                      width: auto;
                      height: auto;
                      font-size: 12px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #b0b0b0;
                    }
                    p {
                      width: auto;
                      height: auto;
                      font-size: 12px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #ffffff;
                    }
                  }
                }
              }
              // 聊天框底部
              .wrapTopsCenCenRCc {
                width: 100%;
                height: 46px;
                background: #5d5d5d;
                display: flex;
                justify-content: center;
                align-items: center;
                // 内容盒子
                .wrapTopsCenCenRCcs {
                  width: 85%;
                  height: 30px;
                  background: #7a7a7a;
                  border-radius: 15px;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  // 输入框
                  input {
                    width: 70%;
                    height: 100%;
                    border: none;
                    outline: none;
                    background: #7a7a7a;
                    color: #ffffff;
                  }
                  // 修改输入框预输入文字
                  input::-webkit-input-placeholder {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #efefef;
                  }
                  // 发送
                  div {
                    width: 24%;
                    height: 100%;
                    background: #a1a1a1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0px 15px 15px 0px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #efefef;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .knowledge_points-box {
    width: 1220px;
    max-height: 580px;
    background-color: #ffffff;
    margin: 20px auto;
    padding: 12px 32px;
    overflow: auto;
  }

  // 直播课内容
  .warpCon {
    width: 100%;
    // height: 920px;
    height: auto;
    margin-bottom: 30px;
    background: #f5f6fa;
    overflow: hidden;
    // 缩宽
    .warpCons {
      width: 65%;
      height: 100%;
      margin: auto;
      overflow: hidden;
      margin-bottom: 50px;
      .warpConss {
        width: 100%;
        height: auto;
        // 单个内容
        .warpConske {
          width: 100%;
          height: 76px;
          margin-top: 30px;
          background: #ffffff;
          border: 1px solid #ffc3bb;
          border-radius: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // 左侧标题
          .warpConskeL {
            width: auto;
            height: 19px;
            margin-left: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 21px;
              height: 19px;
              margin-right: 16px;
              cursor: pointer;
            }
            p {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #27323f;
              cursor: pointer;
              span {
                width: auto;
                height: auto;
                margin-left: 20px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7b8196;
              }
            }
          }
          // 右侧展开/收起
          .warpConskeR {
            width: auto;
            height: 15px;
            margin-right: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            img {
              width: 9px;
              height: 7px;
              margin-left: 7px;
            }
            span {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7b8196;
            }
          }
        }
        // 单个内容展开项盒子
        .warpConskexq {
          width: 100%;
          height: auto;
          background: #ffffff;
          padding: 20px;
          .warpConskexqs {
            width: 1175px;
            height: 50px;
            background: #f7f7f7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 左侧
            .warpConskexqsL {
              width: auto;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 4px;
                height: 50px;
                background: #dddddd;
                margin-right: 27px;
              }
              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
              }
            }
            // 右侧
            .warpConskexqsR {
              width: 320px;
              height: 32px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 90px;
                height: 32px;
                margin-right: 10px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .apple {
      width: 1200px;
      height: 100%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .appleLeft {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;
        .appleLefts {
          width: 100%;
          height: auto;
          /deep/ img {
            width: 100% !important;
          }
        }
      }
    }
  }
  .room {
    width: 320px;
  }
}
#logo {
  position: absolute;
  right: 30px;
  bottom: 50px;
  img {
    height: 40px;
  }
}
/* 章节名称 */
.room-title {
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
/* 讲师 */
.detail-ter {
  flex-shrink: 0;
  .ter {
    color: #fff;
  }
}
.content {
  width: 1220px;
  background: #ffffff;
  margin: 20px auto;
}
/deep/ .wrapTopsCen {
  .boxTips {
    position: absolute;
    width: 192px;
    height: 162px;
    display: none;
    justify-content: flex-start;
    align-items: center;
    color: #ffffff;
    bottom: 55px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 19999;
    border-radius: 8px;
    .marker-img {
      width: 192px;
      height: 108px;
      z-index: 19999;
      border-radius: 8px 8px 0 0;
    }
    .marker-text {
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      width: 192px;
      height: 54px;
      padding: 6px 10px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.dsj {
  position: absolute;
  bottom: -11px;
  border-color: #ffffff transparent transparent transparent;
  border-style: solid;
  border-width: 12px 10px 0 10px;
  height: 0;
  width: 0;
  left: calc(50% - 10px);
}
.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    .contents {
      width: 380px;
      margin: 20px auto;
      & > span {
        color: red;
      }
    }
    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
::v-deep .prism-setting-btn {
  display: none !important;
}
::v-deep .prism-cc-btn {
  display: none !important;
}
</style>
